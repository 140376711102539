import React from "react"
import Img from "gatsby-image"

const galleryImage = props => {
  console.log(props)
  return (
    <>
      <Img fluid={props.image.asset.fluid} className={props.imageWrapper} />
    </>
  )
}

export default galleryImage
