import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import GalleryImage from "../components/galleryImage"
import SEO from "../components/seo"

import galleryStyles from "../styles/gallery.module.scss"

export const query = graphql`
  query galleryQuery {
    images: allSanityGalleryImages {
      edges {
        node {
          image {
            asset {
              fluid(maxWidth: 300, maxHeight: 200) {
                ...GatsbySanityImageFluid
              }
              url
              assetId
            }
          }
          caption
          id
          alt
          title
          slug {
            current
          }
        }
      }
    }
  }
`

const Gallery = props => {
  console.log(props.nodes)
  return (
    <section>
      <SEO title="Photo Gallery" description="Crazy Critters Photo gallery" />
      <h1>Gallery</h1>
      <div className={galleryStyles.galleryContainer}>
        {props.nodes &&
          props.nodes.map(node => (
            <GalleryImage
              {...node}
              key={node.id}
              imageWrapper={galleryStyles.imageWrapper}
            />
          ))}
      </div>
    </section>
  )
}

const GalleryData = ({ data }) => {
  const imageNodes = data && data.images.edges.map(edge => edge.node)
  console.log(imageNodes)
  return <Layout>{imageNodes && <Gallery nodes={imageNodes} />}</Layout>
}

export default GalleryData
